<template>
    <div class="container">
        <div class="innerCircle"></div>
        <div class="text_data">
          <div class="title">{{ titleval }}</div>
        </div>
    </div>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';
    const props = defineProps({valin: Number});
    const {title} = toRefs(props);
    let titleval = ref("...");
    
    
    watch(props, () => {
      titleval.value = props.valin;
    });

   

</script>

<style lang="scss" scoped>
.container {
    position:relative;
    contain: layout;
}
.text_data{
  position: absolute;
  z-index: 5; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title{
    font-size: inherit;
    color:white;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
}
  .innerCircle {
    position: absolute;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    left: 5px;
    top: 5px;
    background-color: #787878;
    border-radius: 8px;
    display: inline-block;
    z-index: 2;
  }
</style>
<template>
  <div class="container">
    <img class="back" src="../boeing/assets/displacementHor-back.svg">
    <img v-if="needleVisible" class="needle" :style="{left: `${gval}%`}" src="../boeing/assets/displacementHor-needle.svg">
    <img class="graduate" src="../boeing/assets/displacementHor.svg">
    <div class="value">{{ val }} in</div>
    <div class="value1">Radial Displacment</div>
  </div>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';
    const props = defineProps({ valin: Number});
    const { valin} = toRefs(props);

    let val = ref(0);
    let gval = ref(0);
    let needleVisible = ref(false);

    watch(props, () => {
        if(valin.value===undefined) {
            val.value = "--";
            needleVisible.value = false;
        } else {
            gval.value = (2*props.valin)+50;
            val.value = props.valin;
            needleVisible.value = true;
        }
    });
</script>

<style lang="scss" scoped>
.container {
    position:relative;
    contain: layout;
}
.back {
    position:absolute;
    left: 0%;
    top: 0%;
    height:100%;
    width:100%;
    z-index:1;
}
.graduate {
    position:absolute;
    left: 0%;
    top: 0%;
    z-index: 2;
    height:100%;
    width:100%;
}
.needle {
    position: absolute;
    transform: translate(-50%);
    left:42%;
    top: 28%;
    height:15%;
    width:15%;
    z-index: 3; 
}
.value {
    position: absolute;
    text-align: center;
    left: 0%;
    top: 77%;
    height:100%;
    width:100%;
    z-index: 4; 
    color:white;
    font-size: 150%;
}
.value1 {
    position: absolute;
    text-align: center;
    left: 0%;
    top: 70%;
    height:100%;
    width:100%;
    z-index: 4; 
    color:white;
    font-size: 75%;
}
</style>
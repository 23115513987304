<template>
    <div class="container">
        <img class="back" src="../boeing/assets/displacementHor-back.svg">
        <img class="top-needle" :style="{left: `${gval_actual}%`}" src="../boeing/assets/steeringAngleHor-topNeedle.svg">
        <img class="bottom-needle" :style="{left: `${gval_theoretical}%`}" src="../boeing/assets/steeringAngleHor-bottomNeedle.svg">
        <img class="graduate" src="../boeing/assets/steeringAngleHor.svg">
        <div class="value1">Steering Angle</div>
        <div class="value">theoretical: {{ val_theoretical }}&deg;<br>actual: {{ val_actual }}&deg;</div>
    </div>
</template>

<script setup>
import { ref, toRefs, watch } from 'vue';
const props = defineProps({ valin_theoretical: Number, valin_actual: Number, width: Number });
const { valin_theoretical, valin_actual, width } = toRefs(props);

let gval_theoretical = ref(0);
let gval_actual = ref(0);
let val_theoretical = ref(0);
let val_actual = ref(0);

watch(props, () => {
    gval_theoretical.value = props.valin_theoretical/4;
    gval_actual.value = props.valin_actual/4;
    val_theoretical.value = props.valin_theoretical;
    val_actual.value = props.valin_actual;
});
</script>

<style lang="scss" scoped>
.container {
    position:relative;
    contain: layout;
}
.back {
    position:absolute;
    left: 0%;
    top: 0%;
    height:100%;
    width:100%;
    z-index:1;
}
.top-needle {
    position: absolute;
    left: 0%;
    top: -10%;
    height:100%;
    width:100%;
    z-index: 2;
}
.bottom-needle {
    position: absolute;
    left: 0%;
    top: 0%;
    height:100%;
    width:100%;
    z-index: 3;
}
.graduate {
    position: absolute;
    left: 0%;
    top: -5%;
    height:100%;
    width:100%;
    z-index: 4;
}
.value {
    position: absolute;
    text-align: center;
    left: 0%;
    top: 77%;
    height:100%;
    width:100%;
    z-index: 5;
    color: white;
    font-size: 80%;
}
.value1 {
    position: absolute;
    text-align: center;
    left: 0%;
    top: 70%;
    height:100%;
    width:100%;
    z-index: 5;
    color: white;
    font-size: 80%;
}
</style>
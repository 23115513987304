<template>
  <div class="container">
    <img class="back" src="../boeing/assets/levelback2.svg">
    <div class="back-mask"></div>
    <img class="profile"  src="../boeing/assets/lcl-top-profile.svg">
    <img :style="{top: `${gleft}%`}" class="left-graduate"  src="../boeing/assets/levelvert.svg">
    <img :style="{top: `${gright}%`}" class="right-graduate"  src="../boeing/assets/levelvertRight.svg">
    <div class="left-indicator">{{left}} in</div>
    <div class="right-indicator">{{right}} in</div>


    <div class="value">{{ val }}&deg;</div>
    <div class="title">Yaw</div>
  </div>
</template>

<script setup>
import { ref, toRefs, watch } from 'vue';
const props = defineProps({ val_left: Number, val_right: Number});
const { val_left,val_right } = toRefs(props);

let left = ref(0);
let right = ref(0);
let gleft = ref(0);
let gright = ref(0);
let val = ref(0);

watch(props, () => {
    left.value = props.val_left;
    right.value = props.val_right;
    
    gleft.value = (25-2.5*props.val_left).toFixed(0);
    gright.value = (25-2.5*props.val_right).toFixed(0);
    
    const v = props.val_right - props.val_left;
    const r = 1314;
    const a = 0 - (Math.asin(v/r) * 180 / Math.PI);
    val.value = a.toFixed(1);
});
</script>

<style lang="scss" scoped>
.container {
    position:relative;
    contain: layout;
    border-radius: 100%;
    overflow: hidden;
}
.back {
    position:absolute;
    left: 0%;
    top: 0%;
    height:100%;
    width:100%;
    z-index:1;
}
.back-mask {
    position:absolute;
    left: 0;
    bottom: 0;
    height: 31%;
    width: 100%;
    z-index:3;
    background-color: #787878;
}
.profile {
    position: absolute;
    left: 0%;
    top: -34%;
    height:100%;
    width:100%;
    z-index: 4;
}
.left-graduate {
    position: absolute;
    left: -1%;
    top: 25%;
    height:50%;
    width:50%;
    z-index: 2;
}
.right-graduate {
    position: absolute;
    left: 50%;
    top: 25%;
    height:50%;
    width:50%;
    z-index: 2;
}
.value {
    position: absolute;
    text-align: center;
    left: 0%;
    top: 70%;
    height:100%;
    width:100%;
    z-index: 5;
    color: white;
    font-size: 12px;
    margin-top: -1px;
}
.title {
    position: absolute;
    text-align: center;
    left: 0%;
    top: 80%;
    height:100%;
    width:100%;
    z-index: 5;
    color: white;
    font-size: 12px;
}
.left-indicator {
    position: absolute;
    text-align: center;
    left: -38%;
    top: 46%;
    height:100%;
    width:100%;
    z-index: 5;
    color: white;
    font-size: 80%;
}
.right-indicator {
    position: absolute;
    text-align: center;
    left: 38%;
    top: 46%;
    height:100%;
    width:100%;
    z-index: 5;
    color: white;
    font-size: 80%;
}
</style>